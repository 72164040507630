<template>
  <CSidebar class="bg-main" fixed :minimize="minimize" :show.sync="show">
    <CSidebarBrand class="d-md-down-none mb-2" to="/">
      <img src="@/assets/logo/logo.png" width="60%" alt="" />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="computedSidebar" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import * as data from "./_nav";

export default {
  name: "TheSidebar",
  data() {
    return {
      role: {},
    };
  },
  methods: {
    getIconForPath(path) {
        let icon = 'cil-file';  // default icon
        if(path === '/dashboard') {
            icon = 'cil-speedometer';
        }
        if(path === '/user') {
            icon = 'cil-user';
        }
        if(path === '/menu') {
            icon = 'cil-menu';
        }
        if(path === '/role') {
            icon = 'cil-task';
        }
        if(path === '/master-team') {
            icon = 'cil-people';
        }
        if(path === '/master-kecamatan') {
            icon = 'cil-building';
        }
        if(path === '/master-item') {
            icon = 'cil-basket';
        }
        if(path === '/transaksi-item-masuk') {
            icon = 'cil-cart';
        }
        if(path === '/transaksi-item-terpakai') {
            icon = 'cil-check';
        }
        if(path === '/transaksi-item-keluar') {
            icon = 'cil-truck';
        }
        if(path === '/transaksi-item-pengaduan') {
            icon = 'cil-frown';
        }
        if(path === '/transaksi-item-penugasan') {
            icon = 'cil-task';
        }
        if(path === '/Menu-Report') {
            icon = 'cil-file';
        }
        
        return icon;
      }
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    getRoleFromLocal() {
      return JSON.parse(localStorage.getItem("user"));
    },
    computedSidebar() {
        let menus = [
            {
                _name: 'CSidebarNavItem',
                name: 'Dashboard',
                to: '/dashboard',
                icon: this.getIconForPath('/dashboard'),
            },
            {
                _name: 'CSidebarNavTitle',
                _children: ['Menu']
            },
            {
              _name: 'CSidebarNavDropdown',
              name: 'Riwayat',  // Name of the dropdown item
              icon: "cil-history",  // Icon for the dropdown
              items: [
                  {
                      name: 'Barang Terpakai',
                      to: '/transaksi-item-terpakai',
                      icon: this.getIconForPath('/transaksi-item-terpakai'),
                  },
                  {
                      name: 'Barang Keluar',
                      to: '/transaksi-item-keluar',
                      icon: this.getIconForPath('/transaksi-item-keluar'),
                  }
                  // ... add as many submenus as needed
              ]
          },
          {
              _name: 'CSidebarNavDropdown',
              name: 'Transaksi',  // Name of the dropdown item
              icon: "cil-dollar",  // Icon for the dropdown
              items: [
                  {
                      name: 'Barang Masuk',
                      to: '/transaksi-item-masuk',
                      icon: this.getIconForPath('/transaksi-item-masuk'),
                  },
              ]
          },
          {
              _name: 'CSidebarNavDropdown',
              name: 'Master',  // Name of the dropdown item
              icon: "cil-user",  // Icon for the dropdown
              items: [
                  {
                      name: 'Item Barang',
                      to: '/master-item',
                      icon: this.getIconForPath('/master-item'),
                  },
                  {
                      name: 'Kecamatan',
                      to: '/master-kecamatan',
                      icon: this.getIconForPath('/master-kecamatan'),
                  },
                  {
                      name: 'Team',
                      to: '/master-team',
                      icon: this.getIconForPath('/master-team'),
                  },
                  {
                      name: 'Role',
                      to: '/role',
                      icon: this.getIconForPath('/role'),
                  },
                  {
                      name: 'Menu',
                      to: '/menu',
                      icon: this.getIconForPath('/menu'),
                  },
                  {
                      name: 'User',
                      to: '/user',
                      icon: this.getIconForPath('/user'),
                  }
                  // ... add as many submenus as needed
              ]
          },
          {
              _name: 'CSidebarNavItem',
              name: 'Pengaduan',
              to: '/transaksi-item-pengaduan',
              icon: this.getIconForPath('/transaksi-item-pengaduan'),
          },
          {
              _name: 'CSidebarNavItem',
              name: 'Penugasan',
              to: '/transaksi-item-penugasan',
              icon: this.getIconForPath('/transaksi-item-penugasan'),
          },
          {
              _name: 'CSidebarNavItem',
              name: 'Report',
              to: '/Menu-Report',
              icon: this.getIconForPath('/Menu-Report'),
          },
        ];

        return [
            {
                _name: "CSidebarNav",
                _children: menus,
            },
        ];
    },
  },
};
</script>


<style>
  .bg-main {
    background: #006262 !important;
  }

  .c-sidebar .c-sidebar-brand {
      color: #fff;
      background: #fff !important;
  }
</style>